<template>
<div>  
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <form class="form-sample">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">                  
                  <div class="row">
                    <div class="col-md-4">
                      <h4 class="card-title">Effect</h4>
                      <div class="">
                        <div class="col-sm-12">
                          <input type="search" name="effect" v-model="effectSearch" id="effect" class="col-sm-10 form-control" v-on:keyup="getEffects" autocomplete="off" :class="{ 'is-invalid':  $v.effectlistModel.$error  }">
                          <div class="cls-sugg" v-if="effectList.length > 0">
                            <div v-for="(itm,i) in effectList" :key="i" class="prod-itm-select">
                              <a href="javascript:void(0)" id="element" @click="selectEffect(itm)" class="btn-default show-element">
                                <span class="prdu-desc">{{itm.disease_name}}</span>
                              </a>
                            </div>
                          </div>
                           <div v-if="$v.effectlistModel.$error" class="invalid-feedback">
                            <span v-if="!$v.effectlistModel.required">Please select effect</span>
                          </div>
                          <div v-if="effectlistModel.length > 0">
                            <div class="tageffect d-flex flex-wrap">
                              <p v-for="(eff, i) in effectlistModel" :key="i" class="me-2 mb-2">
                                <label>{{ eff.effect_name }}</label>
                                <i class="mdi mdi-close" @click="removeEffect(i)"></i>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <h4 class="card-title">Notes</h4>
                      <div class="col-sm-12">
                        <div class="col-sm-10">
                          <textarea v-model="notes" class="form-control" rows="4" cols="5"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <h4 class="card-title">Reports</h4>
                      <div class="col-sm-12">
                        <div class="col-sm-10">
                          <multiselect v-model="reportsselected" :options="reports"  :showLabels="false" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="report_name" track-by="report_name" :preselect-first="true"></multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body row">
                  <div class="form-group row" v-for="(input,k) in prescriptionMedicine" :key="k">
                    <div class="col-sm-3">
                      <input type="search" v-model="searchMedicine[k]" id="medi" class="form-control mb-3 mb-lg-0" v-on:keyup="getMedicines(k)" autocomplete="off" placeholder="Search Medicine...">
                      <div class="cls-sugg" v-if="medicines.length > 0 && selectKey == k">
                        <div v-for="(itm,i) in medicines" :key="i" class="prod-itm-select">
                          <a href="javascript:void(0)" id="element" class="btn-default show-element" @click="selectMedicine(itm,k)">
                            <input type="hidden" :v-model="input.medicine_name = itm.medicine_name" />
                            <input type="hidden" :v-model="input.medicine_id = itm.medicine_id" />
                            <span class="prdu-desc">{{itm.medicine_name}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2"><input type="number" class="form-control mb-3 mb-lg-0" v-model="input.medicine_quantity"  placeholder="Pills"/></div>
                    <div class="col-sm-2"><input type="number" step=".25" class="form-control mb-3 mb-lg-0" v-model="input.medicine_morning"  placeholder="Morning"/></div>
                    <div class="col-sm-2"><input type="number" step=".25" class="form-control mb-3 mb-lg-0" v-model="input.medicine_noon"  placeholder="Noon"/></div>
                    <div class="col-sm-2"><input type="number" step=".25" class="form-control mb-3 mb-lg-0" v-model="input.medicine_evening"  placeholder="Evening"/></div>
                    <span class="col-sm-1">
                      <svg xmlns="http://www.w3.org/2000/svg" @click="addField()" v-show="k == prescriptionMedicine.length-1" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" /></svg>
                      <svg xmlns="http://www.w3.org/2000/svg"  @click="removeField(k)" v-show="k || ( !k && prescriptionMedicine.length > 1)" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /></svg>
                    </span>
                  </div>
                </div>
                <div class="row m-1">
                    <div class="col-md-4">
                      <h4 class="card-title">Next Visit In</h4>
                        <div class="row">
                          <div class="col-md-6 mb-2 mb-lg-0">
                            <input type="number" class="form-control" v-model="next_visit_number" placeholder="Nex Visit In"/>
                          </div>
                          <div class="col-md-6 mb-2 mb-lg-0">
                            <div class="col-sm-10">
                              <multiselect v-model="next_visit_type" :options="next_visit_types"  :showLabels="false" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select" :preselect-first="true"></multiselect>
                            </div>
                          </div>
                        </div>
                        <br/>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" v-if="is_btn_spinner">
              <b-button v-if="is_btn_spinner" variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>Loading...</b-button>
            </div>
            <div class="col-12 col-md-4" v-else>
              <button type="button" @click="submitForm('Yes')" class="col-12 col-lg-6  btn btn-gradient-primary mb-2 mb-lg-0 me-2">Save & Draft</button>
              <button type="button" @click="submitForm('No')" class="col-12 col-lg-4 btn btn-gradient-primary mb-2 mb-lg-0  me-2">Save</button>
            </div>
          </form>                           
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import { required } from "vuelidate/lib/validators";
import string from "../constant/strings.js"

export default {
  name:'Prescriptions',
    title: string.PAGE_TITLE_PRESCRIPTION,
  components: {
    NavBar,
    Footer,
    SideBar,
    VoerroTagsInput,
    Multiselect
  },
  data() {
    return {
      prescriptionMedicine: [{
        medicine_id: "",
        medicine_name: [],
        medicine_quantity: "",
        medicine_morning: "",
        medicine_noon: "",
        medicine_evening: ""
      }],
      medicines:[],
      effectlistModel:[],
      effectList:[],
      notes:'',
      reports:[],
      reportsselected:[],
      is_btn_spinner:false,
      effectSearch:'',
      searchMedicine:[],
      selectKey:'',
      myTimer:null,
      next_visit_number:'',
      next_visit_type:'',
      next_visit_types:["Day", "Month", "Year"],
    }
  },
  validations: {
    effectlistModel:{required}
  }, 
  watch:{
    effectSearch(){
      if(this.effectSearch == ''){
        this.effectList = [];
      }
    }
  }, 
  mounted(){
    this.getAddPrescriptionFun(this.$route.params.id);    
    this.getReportList();
    //this.getEffects();
    document.body.classList.add('sidebar-icon-only');    
  },
  methods:{
    ...mapActions("hospital",["getAddPrescriptionData","getPrescriptionMedicineListData","getICDEffectListData","getPrescriptionReportListData","getPrescriptionData"]),
    
    addField() {
      this.prescriptionMedicine.push({
        medicine_id: "",
        medicine_name: [],
        medicine_quantity: "",
        medicine_morning: "",
        medicine_noon: "",
        medicine_evening: ""
      });
    },
    
    removeField(index) {
      this.prescriptionMedicine.splice(index, 1);
    },

    clearFieldData(index) {
      this.prescriptionMedicine[index].medicine_id = '';
      this.prescriptionMedicine[index].medicine_name = '';
    },

    submitForm(isdraft) {
      this.is_btn_spinner = true;
      let medicineNames = [];

      this.prescriptionMedicine.forEach((el,i)=>{
        let medicineId = '0';
        let mname = '';
        // if(el.medicine_name.length > 0) {
        //   if(el.medicine_name[0].key != '') {
        //     medicineId = el.medicine_name[0].key;
        //   }
        //   mname =  el.medicine_name[0].value;
        // }

        mname = this.searchMedicine[i];

        if(el.medicine_name == ''){
           mname = this.searchMedicine[i];
        }else{
           mname = el.medicine_name;
           medicineId = el.medicine_id;
        }
        let newMedicine = {
          medicine_id: medicineId,
          medicine_name: mname,
          medicine_quantity: el.medicine_quantity,
          medicine_morning: el.medicine_morning,
          medicine_noon: el.medicine_noon,
          medicine_evening: el.medicine_evening
        }
        medicineNames.push(newMedicine);
      });
      let effectNameList = [];            
      this.effectlistModel.forEach((effect)=> {        
        let effectList = {
          effect_id:effect.effect_id,
          effect_name:effect.effect_name,
          icd_code:effect.icd_code
        }
        effectNameList.push(effectList);
      })      
      this.$v.$touch();       
      if (!this.$v.$invalid) {
        let selectReport = [];
        if(this.reportsselected && this.reportsselected.length > 0){
          this.reportsselected.forEach((rpt) => {
            let reportObj =  {
              'report_id' : rpt.report_id,
              'report_name': rpt.report_name
            }
            selectReport.push(reportObj);
          })        
        selectReport = JSON.stringify(selectReport);
      }
      var bodyFormData = new FormData();
      bodyFormData.append('opd_id', parseInt(this.$route.params.id));
      bodyFormData.append('prescription_effect', JSON.stringify(effectNameList));
      bodyFormData.append('prescription_medicine', JSON.stringify(medicineNames));
      bodyFormData.append('prescription_report', selectReport);
      bodyFormData.append('prescription_notes', this.notes);
      bodyFormData.append('saved_as_draft', isdraft);
      bodyFormData.append('opd_status', 'Waiting For Report');
      if(this.next_visit_number.length > 0){
        bodyFormData.append('next_visit_number', this.next_visit_number);
        bodyFormData.append('next_visit_type', this.next_visit_type);
      }

      this.getAddPrescriptionData(bodyFormData).then((response) => {  
        this.is_btn_spinner = false;
        if (response.response_code == 200) {          
          this.$toasted.success("OPD added successfully", {duration: 2000,});
          this.$router.push({ name: 'opdslist' });
        }else{
          this.$toasted.error("Something Went wrong", {duration: 2000,});
        }
      });
      } else {
        this.is_btn_spinner = false;
      }
    },

    getReportList(){
      this.getPrescriptionReportListData({
        filter_value:'',        
      }).then((response) => {        
        if (response.response_code == 200) {
          this.reports = response.data;
        }
      });
    },

    getMedicines(index) {
     clearTimeout(this.myTimer)
     this.myTimer = setTimeout(() => {
           this.selectKey = index;
                 var mediFormData = new FormData();
                 mediFormData.append('filter_value', this.searchMedicine[index]);
                 mediFormData.append('page', 1);
                 mediFormData.append('total_record', '50');
                 this.getPrescriptionMedicineListData(mediFormData).then((response) => {
                   if (response.response_code == 200) {
                     this.medicines = response.data;
                   }else{
                     this.clearFieldData(index);
                     this.medicines = [];
                   }
                 });
     }, 500)
    },

    getEffects() {
     clearTimeout(this.myTimer)
     this.myTimer = setTimeout(() => {
             var bodyFormData = new FormData();
                   bodyFormData.append('filter_value', this.effectSearch);
                   this.getICDEffectListData(bodyFormData).then((response) => {
                     if (response.response_code == 200) {
                       this.effectList = response.data;
                       // response.data.forEach(element => {
                        
                       //   this.effectList.push({ key: element.effect_id, value: element.disease_name,effect_name:element.effect_name, icdcode:element.icd_code })
                       // });
                     }
                   });
         }, 500)
    },

    getAddPrescriptionFun(id) {
      var bodyFormData = new FormData();
      bodyFormData.append('opd_id', id);
      this.getPrescriptionData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          if(response.data.prescription_medicine.length > 0){
            response.data.prescription_medicine.forEach((medicine)=>{
              this.searchMedicine.push(medicine.medicine_name);
              let medicineData = {
                medicine_id: medicine.medicine_id,
                medicine_name: [{
                  key:medicine.medicine_id,
                  value:medicine.medicine_name
                }],
                medicine_quantity: medicine.medicine_quantity,
                medicine_morning: medicine.medicine_morning,
                medicine_noon: medicine.medicine_noon,
                medicine_evening: medicine.medicine_evening
              }
              this.prescriptionMedicine.push(medicineData);            
            })
            this.prescriptionMedicine.splice(0,1);
          }
          if(response.data.prescription_report.length > 0) {
            this.reportsselected = response.data.prescription_report;
          }
          this.notes = response.data.prescription_notes;
          if(response.data.prescription_effect.length > 0){
            response.data.prescription_effect.forEach((e)=>{
              let eff = {
              "key": '',
              "value": e.effect_name,
              "effect_name": e.effect_name,
              "icdcode": e.icd_code
            }
            this.effectlistModel.push(e);
            })            
          }              
        }
      });
    },

    selectEffect(val){
      this.effectlistModel.push(val);
      this.effectSearch = '';
      this.effectList = [];
    },

    removeEffect(val){
      this.effectlistModel.splice(val, 1);
    },

    selectMedicine(val,i){
      this.searchMedicine[i] = val.medicine_name;
      this.medicines = [];
    },

  }
}
</script>
<style scoped>
.cls-sugg {width: auto;height: 172px;overflow: scroll;}
.prod-itm-select {
  padding: 9px;
}
.prod-itm-select a {text-decoration: none;color: unset;}

.tageffect {
  list-style: none; /* Remove default list styling */
  margin: 5px 0; /* Reduced margin for spacing */
  padding: 0; /* Remove default padding */
  display: flex; /* Flexbox for layout */
  flex-wrap: wrap; /* Allow items to wrap */
  align-items: center; /* Center items vertically */
  border: 2px ridge #b66dff; /* Border styling */
  border-radius: 12px; /* Rounded corners */
  background: #fff; /* Background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.tageffect p {
  background: #fff; /* Background for labels */
  border-radius: 12px; /* Rounded corners */
  color: #999; /* Text color */
  padding: 5px 8px; /* Reduced padding for spacing */
  margin: 3px; /* Reduced margin around each tag */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center text vertically */
  transition: color 0.2s, background 0.2s; /* Smooth transitions */
  font-size: 0.85rem; /* Reduced font size for compactness */
}

.tageffect p:hover {
  color: #b66dff; /* Change text color on hover */
  background: rgba(182, 109, 255, 0.1); /* Light background on hover */
}

.tageffect .mdi-close {
  cursor: pointer; /* Pointer cursor */
  font-weight: bold; /* Bold icon */
  color: black; /* Icon color */
  font-size: 18px; /* Slightly reduced icon size */
  margin-left: 5px; /* Space between text and icon */
  transition: color 0.2s; /* Smooth color transition */
}

.tageffect .mdi-close:hover {
  color: #b66dff; /* Change icon color on hover */
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>